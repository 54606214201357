<template>
  <hb-dialog size="sm">
    <hb-dialog-header> {{ title || $t('label.success') }} </hb-dialog-header>
    <hb-dialog-body>
      <p v-html="message" />
    </hb-dialog-body>
    <hb-dialog-footer no-border>
      <hb-btn
        theme="secondary"
        size="block-lg"
        @click="handleClose"
      >
        <span>{{ $t('label.ok') }}</span>
      </hb-btn>
    </hb-dialog-footer>
  </hb-dialog>
</template>

<script lang="ts">
import { useRuntimeConfig } from '#app'
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import { useDialog } from '~/composables'
import { DialogMixin } from '~/mixins'

export default {
  name: 'SuccessDialog',
  components: {
    HbDialogHeader,
    HbDialogBody,
    HbBtn,
    HbDialogFooter,
    HbDialog,
  },
  mixins: [DialogMixin],
  props: {
    message: { type: String, required: true },
    title: { type: String, default: () => null },
    hasTracking: { type: Boolean, default: () => false },
    pageTitle: { type: String, default: () => null },
    pagePath: { type: String, default: () => null },
  },
  setup(props) {
    const dialog = useDialog(props)
    const config = useRuntimeConfig()
    const isExist = (value: string | undefined) => value && value !== 'null'

    const handleClose = () => {
      if (
        typeof (window as any)?.gtag !== 'undefined' &&
        isExist(config.public.gTagId2) && props?.hasTracking
      ) {
        (window as any)?.gtag('event', 'conversion', {
          send_to: `${config.public.gTagId2}/Y_cvCOvj_6YZELbrj8s9`,
          // page_title: props?.pageTitle ?? "Project page",
          // page_path: props?.pagePath ?? "/project",
          // page_location: (window as any).location.href
        });
      }
      dialog.value.close();
    }

    return {
      dialog,
      handleClose
    }
  },
}
</script>

<style scoped lang="scss">
p {
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: 28px;
}
</style>
